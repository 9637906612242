<template>
  <section class="px-3">
    <Header
      :title="titleHeader"
      :token="showToken"
      :background="showBg"
      :coin="coin"
    />
    <!-- <div class="pt-6">
    <b-row class="mx-0">
      <b-col class="p-0">
        <b-nav pills justified class="p-1 bg-burem rounded">
          <b-nav-item to="/leaderboard" class="actives text-hitam shadow-sm rounded">Leaderboard</b-nav-item>
          <b-nav-item to="/redeem" class="text-burem">Redeem</b-nav-item>
        </b-nav>
      </b-col>
    </b-row>
</div> -->
    <div class="pt-6">
      <!-- <b-img fluid alt="Vue logo" :src="detail.image" class="mb-3 w-100">
    </b-img> -->
      <b-row class="justify-content-center h-100 mx-0">
        <b-col align-self="center" class="p-0">
          <b-card no-body class="text-left round-15 mb-3">
            <div class="position-relative w-100 px-3 pt-3">
              <b-img
                  fluid
                  alt="Vue logo"
                  :src="getRewardDetail.banner"
                  class="mb-3 w-100"
                />
                <div class="d-block mb-4">
                  <h5 class="mb-2">Informasi Reward</h5>
                  <b-table-simple
                  :bordered="true"
                  responsive
                  >
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center">Jenis Reward</b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td class="text-center">{{ getRewardDetail.title }}</b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
                <div class="d-block mb-4">
                  <h5 class="mb-2">Batas Waktu Reward</h5>
                  <p>
                    {{ getRewardDetail.priode }}
                  </p>
                </div>
                <div class="d-block mb-4">
                  <h5 class="mb-2">Pengumuman Pemenang</h5>
                  <p>
                    Pemenang Reward mingguan diumumkan tanggal {{ getRewardDetail.winner_date }}
                  </p>
                </div>
                <div class="d-block mb-4">
                  <h5 class="mb-2">Pengiriman Reward</h5>
                  <p>
                    reward mingguan akan dikirimkan tanggal {{ getRewardDetail.reward_date }}
                  </p>
                </div>
                <div class="d-block mb-4">
                  <h5 class="mb-2">Cara Memenangkan Reward</h5>
                  <p>
                    {{ getRewardDetail.description }}
                  </p>
                </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Header from "@/components/Header.vue";
import { mapActions, mapGetters } from 'vuex';
// import { mapState } from 'vuex';
export default {
  name: "DetailRedeem",
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Pesta Reward",
      showToken: false,
      showBg: true,
      // totalCoin: this.getCoin,
      detail: {},
      rewardId: this.$route.params.id,
      content: `<h4>Detail Information</h4>
      <p> bla bla bla</p>`,
    };
  },
  mounted() {
    this.listRewardDetail(this.rewardId)
  },
  computed: {
    ...mapGetters([
      'getRewardDetail',
    ]),
    // ...mapState([
    //   'redeemList',
    //   'coin',
    // ])
  },
  methods: {
    ...mapActions([
      'listRewardDetail'
    ]),
    // getDetail(id) {
    //   const redeem = this.redeemList
    //   const index = redeem.findIndex(item => item.id === parseInt(id))
    //   // console.log(games[0]);
    //   this.detail = redeem[index]
    //   console.log(redeem, index, id);
    // },
    // handleClick() {
    //   console.log('fungsi akses api redeem');
    //   this.$router.push({ name : 'redeem'})
    // }
  },
};
</script>

<style>
body {
  background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #eff0f6 !important;
}
.text-burem a {
  color: #a0a3bd !important;
}
.text-hitam a {
  color: #212121 !important;
}
.round-15 {
  border-radius: 15px;
}
</style>
