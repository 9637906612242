<template>
  <div>
    <b-row
      cols-lg="4"
      cols-md="2"
      cols-sm="2"
      cols="2"
      class="h-100 mx-0"
    >
      <b-col
        align-self="center"
        v-for="(list, index) in listgame"
        :key="`game-${index}`"
      >
        <b-card
          @click="handleClick(list.id)"
          align-self="center"
          :img-src="imageAsset(list.image)"
          img-alt="Image"
          img-top
          tag="div"
          style="max-width: 20rem;min-width:110px;border-radius: 1.5rem;"
          class="my-3 justify-content-center shadow"
        >
          <div
            style="position: absolute;
                  bottom: 2rem;
                  left: 0.5rem;
                  font-size: 0.9rem;
                  color: white;"
          >
            <div class="d-flex align-items-center mb-4">
              <!-- <span class="mr-auto"> {{ list.game_name }}</span> -->
            </div>
          </div>
          <b-button 
            block
            class="bg-transparent border-0 btn-sm font-weight-bold"
            variant="light"
            style="font-size:0.8rem;"
            >{{ list.game_name }}</b-button
          >
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
// import { apiPortal } from '@/provider'
export default {
  name: "Gallery",
  // props: {},
  // data() {
  //   return {
  //     lists: [],
  //     url: null
  //   };
  // },
  mounted() {
    // if (this.$store.state.listgame.length === 0) {
      this.getdata() 
    // }
    // console.log(this.lists);
  },
  computed: {
    ...mapState([
            'listgame',
        ])
  },
  methods: {
    handleClick(id) {
      this.$router.push({ path: `/game/${id}` })
    },
    ...mapActions([
      'getGames'
    ]),
    getdata() {
      // apiPortal.get('games').then(res => {
      //   // console.log(this.CryptoJS.AES.JSON.decrypt.decrypt(res.data.payload, 'Bismillah').toString())
      //   // this.lists = this.CryptoJS.AES.decrypt(res.data.payload, 'Bismillah').toString()
      //   // this.$store.state.listgame = JSON.parse(this.CryptoJS.AES.decrypt(res.data.payload, 'Bismillah').toString())
      //   this.lists = res.data.payload
      //   this.$store.state.listgame = res.data.payload
      // })
      // this.setStore()
      this.getGames()
    },
    imageAsset(img) {
      const iUrl = img.replace(/^http:\/\//i, 'https://');
      // const iUrl = img
      return iUrl;
    }
    // setStore() {
    //   this.$store.commit("getUrl", this.url);
    //   this.$store.commit("getListGame", this.list)
    // }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.card-body {
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.card-img-top {
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
</style>
