<template>
  <section class="px-3">
    <Header :title="titleHeader"  :token="showToken" :background="showBg" :coin="coin" />
    <div class="pt-6">
      <div class="text-left">
        <h5 class="px-1 font-weight-bold mb-3">{{ detail.name }}</h5>
      </div>
      <b-img
        v-if="detail.image !== ''"
        fluid
        alt="Vue logo"
        :src="detail.image"
        class="mb-3 w-100"
      >
      </b-img>
      <div class="text-left">
        <!-- <h5 class="px-3">{{detail.name}}</h5> -->
        <p class="px-3 text-start" v-html="detail.description"></p>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue'
import { mapState } from 'vuex';
export default {
  name: 'DetailInbox',
  components: {
    Header,
  },
  data() {
    return {
      titleHeader: "Inbox",
      showToken: false,
      showBg: true,
      // totalCoin: this.getCoin,
      detail: {},
      inboxId: this.$route.params.id,
    };
  },
  mounted() {
    // this.$store.state.gameId = this.$route.params.id
    if(this.inboxList.length !== 0) {
      this.getDetail(this.inboxId)
      // this.setPopupToken()
    } else {
      this.$router.push({ name : 'redeem'})
    }
  },
  computed: {
    // ...mapGetters([
    //   'getCoin',
    //   'getRedeem',
    // ]),
    ...mapState([
      'inboxList',
      'coin',
    ])
  },
  methods: {
    getDetail(id) {
      const ibx = this.inboxList
      const index = ibx.findIndex(item => item.id === parseInt(id))
      // console.log(games[0]);
      this.detail = ibx[index]
    //   console.log(ibx, index, id);
    },
    handleClick() {
    //   console.log('fungsi akses api redeem');
      this.$router.push({ name : 'redeem'})
    }
  }
}
</script>

<style>
body {
    background-color: white;
}
</style>
<style scoped>
.leaderboard {
  margin-top: 7.5rem;
}
.actives {
  background-color: #fff !important;
  font-weight: 600;
}
.bg-burem {
  background-color: #EFF0F6 !important;
}
.text-burem a{
  color: #A0A3BD !important;
}
.text-hitam a{
  color: #212121 !important;
}
</style>
